/* eslint-disable prettier/prettier */
import { useMemo, useState } from 'react';
import { ToastFullContext } from 'src/component/base-component/base-snackbar';
import {
  IAccountingEntries,
  IListAccountingEntriesInformations, IListAccountingEntriesResponseDTO
} from 'src/service/service-company';


import { IconButton, Stack } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { BaseDropDownInput } from 'src/component/base-component/base-dropdown';
import { BaseInputAutocomplete } from 'src/component/base-component/base-input-autocomplete';
import { MenuDotIcon } from 'src/component/icons/menu-dot';
import { ITableModalType } from 'src/component/table/filterModal/table-filter-modal';
import { ColumnsCommon } from 'src/component/table/header';
import { HeaderWithFilter } from 'src/component/table/header-with-filter';
import { formatMoney } from 'src/shared/util/formatter/formatter-utils';
import { ListLedgerAccountsUseCase } from 'src/usecase/bank-account/usecase-account-get-ledger-accounts';
import { ListAccountingEntriesUseCase } from 'src/usecase/classifier/usecase-accounting-entries-list';
import { UpdateAccountingEntriesUseCase } from 'src/usecase/classifier/usecase-accounting-entries-update';
import { ListCompanyVendorsUseCase } from 'src/usecase/classifier/usecase-company-vendors-list';

export type IFilterModalField = 'date' | 'description' | 'client' | 'value' | 'credit'  | 'debit'
export type IFilterModalType = {
  modalType: ITableModalType,
  fieldType: IFilterModalField,
  open: boolean
}

export function useClassifier(
  listAccoutingEntriesUseCase: ListAccountingEntriesUseCase,
  updateAccountingEntriesUseCase: UpdateAccountingEntriesUseCase,
  listCompanyVendorsUseCase: ListCompanyVendorsUseCase,
  listLedgerAccountsUseCase: ListLedgerAccountsUseCase,
  companyId: string,
) {
  const { ShowToast } = ToastFullContext();
  
  const [isLoading, setIsLoading] = useState(false);
  const [selectedGridIds, setSelectedGridIds] = useState<string[]>([]);
  const [accountingEntries, setAccountingEntries] = useState<
    IAccountingEntries[]
  >([]);
  const [informations, setInformations] = useState<IListAccountingEntriesInformations>()
  const [vendors, setVendors] = useState<string[]>([]);
  const [debits, setDebits] = useState<string[]>([]);
  const [credits, setCredits] = useState<string[]>([]);
  const [columnsTableSelected, setColumnsTableSelected] = useState<string[]>([
    'date',
    'description',
    'debit',
    'credit',
    'value',
  ]);
  const [currentFilterModal, setCurrentFilterModal] = useState<IFilterModalType | undefined>(undefined)
  const [totalRegisters, setTotalRegisters] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const handleSelectColumns = (column: string) => {
    const exists = columnsTableSelected.find(i => i === column);

    if (exists) {
      const filtered = columnsTableSelected.filter(i => i !== column);
      setColumnsTableSelected(filtered);
    } else {
      setColumnsTableSelected(prev => [...prev, column]);
    }
  };

  const handleUpdateAccountingEntry = async ({
    entryId,
    oldValue,
    newValue,
    field,
  }: {
    entryId: string;
    oldValue: string;
    newValue: string;
    field: string;
  }) => {
    try {
      const normalizeNewValue = newValue === '' ? null : newValue;
      if (oldValue === normalizeNewValue) return;

      const usecaseResult = await updateAccountingEntriesUseCase.handle({
        companyId,
        entryId,
        field,
        value: newValue,
      });

      if (usecaseResult.isFailure) {
        ShowToast(
          'error',
          'Ocorreu um erro ao atualizar os dados do classificador. Tente novamente mais tarde.',
        );
      }
    } catch (error) {
      console.log('error: ', error);
      ShowToast('error', 'Ocorreu um erro ao buscar atualizar os dados.');
    }
  };

  const vendorsOptions = useMemo(() => {
    const payload = [
      { value: 'none', label: 'Sem fornecedor' },
    ];
    if (vendors?.length) {
      const normalize = vendors?.map(vendor => ({
        value: vendor,
        label: vendor,
      }));
      return [...payload, ...normalize];
    } else {
      return payload;
    }
  }, [vendors]);

  const handleListAccountingEntries = 
    async (
      companyId: string,
      page: number,
      initialDate: Date | undefined,
      finalDate: Date | undefined,
      minValue: string | undefined,
      maxValue: string | undefined,
      accountsSelected: string[],
      selectedVendors: string[] | undefined,
      query?: string | undefined,
      accountingEntriesAlreadyClassified?: 'true' | 'false' | undefined
    ) => {
      
      if (accountsSelected.length === 0) {
        setAccountingEntries([])
        return
      }

      try {
        setIsLoading(true);
  
        // Chamada à API para obter os dados
        const usecaseResult = await listAccoutingEntriesUseCase.handle({
          companyId,
          bankAccountId: accountsSelected,
          page,
          vendor: selectedVendors,
          dateStart: initialDate,
          dateEnd: finalDate,
          query,
          valueMaximum: maxValue,
          valueMinimum: minValue,
          accountingEntriesAlreadyClassified,
        });
        if (usecaseResult.isFailure) {
          ShowToast(
            'error',
            'Ocorreu um erro ao buscar os dados do classificador. Tente novamente mais tarde.',
          );
          setIsLoading(false);
          return;
        }
  
        const transfersData =
          usecaseResult.getValue() as IListAccountingEntriesResponseDTO;

        const normalize: IAccountingEntries[] =
          transfersData?.companyAccountingEntries?.map((entry) => {
            let description = "Transferência -";
            let partieName = ''

            if (
              entry.bankTransferPartie.name ||
              entry.bankTransferPartie.document ||
              entry.bankTransferPartie.type
            ) {
              if (entry.bankTransferPartie.name) {
                description += ` ${entry.bankTransferPartie.name}`;
                partieName = `${entry.bankTransferPartie.name} ${entry.bankTransferPartie.document ? entry.bankTransferPartie.document : ''}`
              }
              if (![null, undefined, ""].includes(entry.bankTransferType)) {
                description += ` ${entry.bankTransferType}`;
              }
              if (entry.bankTransferPartie.type) {
                description += ` ${
                  entry.bankTransferPartie.type === "PESSOA_NATURAL" ? "PF" : "PJ"
                }`;
              }
              if (entry.bankTransferPartie.document) {
                if (entry.bankTransferPartie.document.length > 11) {
                  const formattedDocument = entry.bankTransferPartie.document;
                  description += ` ${formattedDocument}`;
                } else {
                  const formattedDocument = entry.bankTransferPartie.document;
                  description += ` ${formattedDocument}`;
                }
              }
            } else {
              if (![null, undefined, ""].includes(entry.bankTransferType)) {
                description += ` ${entry.bankTransferType}`;
              }
              if (!entry.bankTransferType) {
                if (entry.description) {
                  description += ` ${entry.description}`;
                } else {
                  description += ` ${entry.method}`;
                }
              }
            }
            return {
              id: entry.id,
              date: format(new Date(entry.date), 'dd/MM/yyyy'),
              bankTransferDate: format(new Date(entry.bankTransferDate), 'dd/MM/yyyy'),
              description,
              supplier: partieName,
              value: formatMoney(Number(entry.value)),
              debit: entry?.debit,
              credit: entry?.credit,
              historic: entry?.historic,
              financialCategory: entry?.financialCategory,
              status: entry?.status,
              conciliatedEntries: entry?.conciliatedEntries,
            }
          }) || [];
          
        if (normalize.length) {
          if (page === 1) {
            setAccountingEntries(normalize);
          } else {
            setAccountingEntries(prev => {
              const updated = [...prev, ...normalize];
              return updated;
            });
          }
          
        } else {
          setAccountingEntries([])
        }
  
        setInformations(transfersData.informations)
        setCurrentPage(page);
        setTotalPages(transfersData.pages);
        setTotalRegisters(transfersData.count);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        ShowToast('error', 'Ocorreu um erro ao buscar os dados da listagem.');
      }
    };

  const handleListCompanyVendors = async (companyId: string) => {
    try {
      setIsLoading(true);
      const usecaseResult = await listCompanyVendorsUseCase.handle(companyId);
      setIsLoading(false);

      if (usecaseResult.isFailure) {
        ShowToast(
          'error',
          'Ocorreu um erro ao buscar os dados dos clientes e fornecedores. Tente novamente mais tarde.',
        );
      }

      const value = usecaseResult.getValue();

      if (value) {
        setVendors(value);
      }
    } catch (error) {
      setIsLoading(false);
      ShowToast('error', 'Ocorreu um erro ao buscar o dados dos clientes e fornecedores.');
    }
  };

  const handleGeLedgerAccounts = async () => {
    setIsLoading(true)
    const ledgerAccountResult = await listLedgerAccountsUseCase.handle({
      companyId: companyId as string,
      query: '',
    })

    if (ledgerAccountResult.isFailure) {
      ShowToast(
        'error',
        'Ocorreu um erro ao buscar a contas bancária. Tente novamente mais tarde.',
      )
    }

    const ledgerAccountData = ledgerAccountResult.getValue()
    setCredits(ledgerAccountData?.credits ?? [])
    setDebits(ledgerAccountData?.debits ?? [])
    setIsLoading(false)
  }

  const HistoricVariables = [
    {
      label: 'Data',
      value: 'data',
    },
    {
      label: 'Descrição',
      value: 'descricao',
    },
    {
      label: 'Valor',
      value: 'valor',
    },
    {
      label: 'Débito',
      value: 'debito',
    },
    {
      label: 'Crédito',
      value: 'credito',
    },
  ]
  const columns = useMemo(() => {
    const order = [
      'Data',
      'Descrição - Clientes e fornecedor',
      // 'Clientes e fornecedor',
      'Valor',
      'Débito',
      'Crédito',
      'Categoria financeira',
      'Histórico',
      'Ações',
    ]
    const makeGrid: GridColDef[] = [
      // {
      //   ...ColumnsCommon,
      //   flex: 1,
      //   align: 'left',
      //   ...{
      //     minWidth: 350,
      //     field: 'Clientes e fornecedor',
      //     renderHeader: () => (
      //       <HeaderWithFilter
      //         title="Clientes e fornecedores"
      //         clickEvent={() =>
      //           setCurrentFilterModal({modalType: 'text-with-filter', fieldType: 'client', open: true})
      //         }
      //       />
      //     ),
      //   },
      //   renderCell: (params) => `${params.row.supplier}`,
      // },
      {
        ...ColumnsCommon,
        flex: 0.6,
        align: 'center',
        minWidth: 250,
        ...{
          field: 'Histórico',
          headerName: 'Histórico',
        },
        renderCell: ({ row }) => (
          <Stack width="100%">
          <BaseDropDownInput
            options={HistoricVariables}
            error=""
            setState={(e) => handleUpdateAccountingEntry({ entryId: row.id, field: 'historic', newValue: e?.join('|'), oldValue: row.financialCategory })}
            value={row.financialCategory}
          />
          </Stack>
        ),
      },
      {
        ...ColumnsCommon,
        align: 'center',
        flex: 0.6,
        ...{
          field: 'Categoria financeira',
          headerName: 'Categoria financeira',
        },
        renderCell: ({ row }) => (
          <Stack>
            <BaseInputAutocomplete
              companyId={companyId}
              options={credits}
              variant="credits"
              error=""
              label=""
              setState={(e) => handleUpdateAccountingEntry({ entryId: row.id, field: 'financialCategory', newValue: e, oldValue: row.financialCategory })}
              value={row.financialCategory}
              inputVariant='bottomBordered'
            />
          </Stack>
        ),
      },
      {
        ...ColumnsCommon,
        flex: 0.5,
        ...{
          field: 'Ações',
          headerName: 'Ações',
          minWidth: 100,
          align: 'left',
          renderCell: (params) => (
            <IconButton
              sx={{
                backgroundColor: '#ffffff',
                color: '#4865CC',
                marginLeft: '15px',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                width: '40px',
                height: '40px',
                padding: 0,
              }}
              onClick={() => console.log('empty')}
            >
              <MenuDotIcon />
            </IconButton>
          ),
        },
      },
    ]

    if (columnsTableSelected.includes('date')) {
      makeGrid.unshift({
        ...ColumnsCommon,
        flex: 0.5,
        ...{
          field: 'Data',
          minWidth: 130,
          renderHeader: () => (
            <HeaderWithFilter
              title="Data"
              clickEvent={() =>
                setCurrentFilterModal({modalType: 'between-dates-filter', fieldType: 'date', open: true})
              }
            />
          ),
        },
        renderCell: (params) => `${params.row.bankTransferDate || ''}`,
      })
    }

    if (columnsTableSelected.includes('description')) {
      makeGrid.unshift({
        ...ColumnsCommon,
        flex: 1,
        align: 'left',
        ...{
          field: 'Descrição - Clientes e fornecedor',
          minWidth: 350,
          renderHeader: () => (
            <HeaderWithFilter
              title="Descrição - Clientes e fornecedor"
              clickEvent={() =>
                setCurrentFilterModal({modalType: 'text-with-filter', fieldType: 'description', open: true})
              }
            />
          ),
        },
        renderCell: (params) => `${params.row.description}`,
      })
    }

    if (columnsTableSelected.includes('value')) {
      makeGrid.unshift({
        ...ColumnsCommon,
        flex: 0.6,
        align: 'left',
        ...{
          field: 'Valor',
          minWidth: 150,
          renderHeader: () => (
            <HeaderWithFilter
              title="Valor"
              clickEvent={() =>
                setCurrentFilterModal({modalType: 'between-values-filter', fieldType: 'value', open: true})
              }
            />
          ),
        },
        renderCell: (params) => `${params.row.value}`,
      })
    }

    if (columnsTableSelected.includes('debit')) {
      makeGrid.unshift({
        ...ColumnsCommon,
        flex: 0.6,
        align: 'left',
        ...{
          field: 'Débito',
          headerName: 'Débito',
          minWidth: 150,
          renderHeader: () => (
            <HeaderWithFilter
              title="Débito"
              clickEvent={() =>
                setCurrentFilterModal({modalType: 'select-options', fieldType: 'debit', open: true})
              }
            />
          ),
        },
        renderCell: ({ row }) => (
          <Stack>
            <BaseInputAutocomplete
              companyId={companyId}
              options={debits}
              variant="debits"
              error=""
              label=""
              setState={(e) => handleUpdateAccountingEntry({ entryId: row.id, field: 'debit', newValue: e, oldValue: row.debit })}
              value={row.debit}
              inputVariant='bottomBordered'
            />
          </Stack>
        ),
      })
    }

    if (columnsTableSelected.includes('debit')) {
      makeGrid.unshift({
        ...ColumnsCommon,
        flex: 0.6,
        align: 'left',
        ...{
          field: 'Crédito',
          headerName: 'Crédito',
          minWidth: 150,
          renderHeader: () => (
            <HeaderWithFilter
              title="Crédito"
              clickEvent={() =>
                setCurrentFilterModal({modalType: 'select-options', fieldType: 'credit', open: true})
              }
            />
          ),
        },
        renderCell: ({ row }) => (
          <Stack>
            <BaseInputAutocomplete
              companyId={companyId}
              options={credits}
              variant="credits"
              error=""
              label=""
              setState={(e) => handleUpdateAccountingEntry({ entryId: row.id, field: 'credit', newValue: e, oldValue: row.credit })}
              value={row.credit}
              inputVariant='bottomBordered'
            />
          </Stack>
        ),
      })
    }

    return order
      .map((key) => makeGrid.find((column) => column.field === key))
      .filter((column): column is GridColDef => column !== undefined)
  }, [vendorsOptions, columnsTableSelected])

  return {
    columns,
    isLoading,
    selectedGridIds,
    setSelectedGridIds,
    setIsLoading,
    handleListAccountingEntries,
    accountingEntries,
    handleListCompanyVendors,
    handleSelectColumns,
    columnsTableSelected,
    totalPages,
    totalRegisters,
    currentPage,
    informations,
    vendorsOptions,
    currentFilterModal,
    handleGeLedgerAccounts,
    credits,
    debits,
  };
}
