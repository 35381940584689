import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import SendOutlinedIcon from '@mui/icons-material/SendOutlined'
import {
  Button,
  Checkbox,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useState } from 'react'
import { BaseDropDownInput } from 'src/component/base-component/base-dropdown'
import { BaseInputMoney } from 'src/component/base-component/base-input-money'
import { ModalFullContext } from 'src/component/modal/modal-provider'
import {
  CompanyClassificationRuleConditionalsConditionEnum,
  CompanyClassificationRuleConditionalsConditionEnumUtil,
  CompanyClassificationRuleConditionalsVariableEnum,
  ICompanyClassificationRuleConditionals,
} from 'src/service/service-company'
import { CreateForm } from 'src/shared/util/form/form-util'
import { CreateAccountClassificationRuleUseCase } from 'src/usecase/company/usecase-company-create-classification-rule'
import { BaseInput } from '../../base-component/base-input'
import { ToastFullContext } from '../../base-component/base-snackbar'

const HistoricVariables = [
  {
    label: 'Data',
    value: 'data',
  },
  {
    label: 'Descrição',
    value: 'descricao',
  },
  {
    label: 'Valor',
    value: 'valor',
  },
  {
    label: 'Débito',
    value: 'debito',
  },
  {
    label: 'Crédito',
    value: 'credito',
  },
]

export const ClassificationRuleModal: React.FC<{
  companyId: string
  createClassificationRuleUseCase: CreateAccountClassificationRuleUseCase
  refetchAction: () => Promise<void>
}> = ({ companyId, createClassificationRuleUseCase, refetchAction }) => {
  const theme = useTheme()
  const { HideModal } = ModalFullContext()
  const { ShowToast } = ToastFullContext()
  const [isLoading, setIsLoading] = useState(false)
  const [variable, setVariable] =
    useState<CompanyClassificationRuleConditionalsVariableEnum | null>(null)
  const [condition, setCondition] =
    useState<CompanyClassificationRuleConditionalsConditionEnum | null>(null)
  const [rules, setRules] = useState<ICompanyClassificationRuleConditionals[]>(
    [],
  )
  const isSmallScreen = useMediaQuery(`(max-width: 1024px)`)
  const [variableThirdPartyName, setVariableThirdPartyName] =
    useState<string>('')
  const [variableThirdPartyDocument, setVariableThirdPartyDocument] =
    useState<string>('')
  const [variableValue, setVariableValue] = useState<string>('')
  const [variableDescription, setVariableDescription] = useState<string>('')
  const [variableDay, setVariableDay] = useState<string>('')
  const [variableEndDay, setVariableEndDay] = useState<string>('')
  const [generalRule, setGeneralRule] = useState<boolean>(false)
  const [showGeneralRuleWarning, setShowGeneralRuleWarning] =
    useState<boolean>(false)

  const classificationForm = CreateForm({
    fields: [
      'code',
      'name',
      'credit',
      'debit',
      'historic',
      'financialCategory',
    ],
    validation: createClassificationRuleUseCase?.validation,
  })

  const handleDeleteVariable = (
    item: ICompanyClassificationRuleConditionals,
  ) => {
    const currentRules = [...rules]
    const newRules = currentRules.filter((objeto) => objeto !== item)
    setRules(newRules)
  }

  const handleCloseModal = () => {
    classificationForm.fields.code.setState('')
    classificationForm.fields.name.setState('')
    classificationForm.fields.credit.setState(undefined)
    classificationForm.fields.debit.setState(undefined)
    classificationForm.fields.historic.setState([])
    classificationForm.fields.financialCategory.setState(undefined)
    setShowGeneralRuleWarning(false)
    setGeneralRule(false)
    setRules([])
    setCondition(null)
    setVariable(null)
    setVariableThirdPartyName('')
    setVariableThirdPartyDocument('')
    setVariableValue('')
    setVariableDescription('')
    setVariableDay('')
    setVariableEndDay('')
    setIsLoading(false)
    HideModal()
  }

  const handleCreateAcountClassificationRuleUsecase = async () => {
    setIsLoading(true)
    const data = {
      accountingChartItem: {
        code: classificationForm.fields.code.value as string,
      },
      name: classificationForm.fields.name.value as string,
      conditionals: rules,
      generalRule,
      credit: classificationForm.fields.credit.value,
      debit: classificationForm.fields.debit.value,
      historic: classificationForm.fields.historic.value,
      financialCategory: classificationForm.fields.financialCategory.value,
    }

    const createResult = await createClassificationRuleUseCase.handle({
      companyId,
      data,
    })

    if (createResult.isFailure) {
      ShowToast(
        'error',
        'Ocorreu um problema durante a criação da regra. Tente novamente.',
      )
      setIsLoading(false)
      return
    }

    await refetchAction()
    setIsLoading(false)
    ShowToast('success', 'Regra de classificação criada com sucesso.')
    handleCloseModal()
  }

  const handleValidate = async () => {
    try {
      if (
        !classificationForm.fields.name.value &&
        classificationForm.fields.code.value
      ) {
        classificationForm.fields.name.setState(
          `Regra - ${classificationForm?.fields?.code?.value?.trim()}`,
        )
      }

      if (!classificationForm.fields.historic.value) {
        classificationForm.fields.historic.value = []
      }

      const validationResult = await classificationForm?.validate()

      if (validationResult.isFailure) {
        setIsLoading(false)
        ShowToast('error', 'Verifique todos os campos')
        return
      }

      if (
        !classificationForm.fields.debit.value &&
        !classificationForm.fields.credit.value &&
        !classificationForm.fields.historic.value &&
        !classificationForm.fields.financialCategory.value
      ) {
        setIsLoading(false)
        ShowToast(
          'error',
          'Pelo menos um dos campos credito, debito, histórico ou categoria financeira deve ser preenchido.',
        )
        return
      }

      handleCreateAcountClassificationRuleUsecase()
    } catch (error) {
      setIsLoading(false)
      ShowToast(
        'error',
        'Ocorreu um erro ao criar a regra. Tente novamente mais tarde.',
      )
    }
  }

  const variableButton = (
    description: string,
    value: CompanyClassificationRuleConditionalsVariableEnum,
  ) => {
    let backgroundColor = '#1E3466'

    if (variable && variable === value) {
      backgroundColor = '#777C84'
    }
    if (variable && variable !== value) {
      backgroundColor = '#CCCCCC'
    }

    if (generalRule) {
      backgroundColor = '#CCCCCC'
    }

    return (
      <Stack
        onClick={() => {
          if (generalRule) return

          setVariable(value)
          setCondition(null)
        }}
        padding="8px 16px 8px 16px"
        gap="8px"
        borderRadius="16px"
        color="#FFFFFF"
        sx={{
          backgroundColor,
          cursor: generalRule ? 'not-allowed' : 'pointer',
          '&:hover': {
            backgroundColor: '#777C84',
          },
        }}
      >
        {description}
      </Stack>
    )
  }

  const conditionButton = (
    description: string,
    value: CompanyClassificationRuleConditionalsConditionEnum,
  ) => {
    let backgroundColor = '#5ED1A2'

    if (condition && condition === value) {
      backgroundColor = '#777C84'
    }
    if (condition && condition !== value) {
      backgroundColor = '#CCCCCC'
    }
    if (generalRule) {
      backgroundColor = '#CCCCCC'
    }

    return (
      <Stack
        onClick={() => {
          if (generalRule) return
          setCondition(value)
        }}
        padding="8px 16px 8px 16px"
        gap="8px"
        borderRadius="16px"
        color="#FFFFFF"
        sx={{
          backgroundColor,
          cursor: generalRule ? 'not-allowed' : 'pointer',
          '&:hover': {
            backgroundColor: '#777C84',
          },
        }}
      >
        {description}
      </Stack>
    )
  }

  const handleAddNewVariable = () => {
    let data = null

    if (
      variable === CompanyClassificationRuleConditionalsVariableEnum.THIRD_PARTY
    ) {
      if (
        [null, undefined, ''].includes(variableThirdPartyName) ||
        [null, undefined, ''].includes(variableThirdPartyDocument)
      ) {
        ShowToast(
          'error',
          'Os dados de nome e documento não foram preenchidos.',
        )
        return
      }

      data = {
        value: undefined,
        date: undefined,
        thirdParty: {
          name: variableThirdPartyName,
          documentNumber: variableThirdPartyDocument,
        },
        description: undefined,
      }
    }

    if (variable === CompanyClassificationRuleConditionalsVariableEnum.DATE) {
      if ([null, undefined, ''].includes(variableDay)) {
        ShowToast('error', 'O campo dia não foi preenchido.')
        return
      }

      if (
        condition === CompanyClassificationRuleConditionalsConditionEnum.BETWEEN
      ) {
        if ([null, undefined, ''].includes(variableEndDay)) {
          ShowToast('error', 'O campo dia não foi preenchido.')
          return
        }

        if (Number(variableEndDay) >= 32 || Number(variableEndDay) <= 0) {
          ShowToast('error', 'O campo dia fim precisa estar entre 1 e 31.')
          return
        }
      }

      if (Number(variableDay) >= 32 || Number(variableDay) <= 0) {
        ShowToast('error', 'O campo dia precisa estar entre 1 e 31.')
        return
      }

      data = {
        value: undefined,
        date: {
          init: variableDay,
          end:
            condition ===
            CompanyClassificationRuleConditionalsConditionEnum.BETWEEN
              ? variableEndDay
              : variableDay,
        },
        thirdParty: undefined,
        description: undefined,
      }
    }

    if (
      variable === CompanyClassificationRuleConditionalsVariableEnum.DESCRIPTION
    ) {
      if ([null, undefined, ''].includes(variableDescription)) {
        ShowToast('error', 'O campo de descrição não foi preenchido.')
        return
      }

      data = {
        value: undefined,
        date: undefined,
        thirdParty: undefined,
        description: {
          description: variableDescription,
        },
      }
    }

    if (variable === CompanyClassificationRuleConditionalsVariableEnum.VALUE) {
      if ([null, undefined, ''].includes(variableValue)) {
        ShowToast('error', 'O campo de valor não foi preenchido.')
        return
      }

      data = {
        value: {
          value: variableValue,
        },
        date: undefined,
        thirdParty: undefined,
        description: undefined,
      }
    }

    const newRule = {
      condition,
      variable,
      data,
    } as ICompanyClassificationRuleConditionals

    const prevRules = [...rules]
    prevRules.push(newRule)
    setRules(prevRules)
    setCondition(null)
    setVariable(null)
    setVariableThirdPartyName('')
    setVariableThirdPartyDocument('')
    setVariableValue('')
    setVariableDescription('')
    setVariableDay('')
    setVariableEndDay('')
  }

  const handleChangeGeneralRule = () => {
    // generalRule está atualmente como false e vai ser habilitado
    if (!generalRule) {
      if (!showGeneralRuleWarning && rules?.length > 0) {
        setShowGeneralRuleWarning(true)
        return ShowToast(
          'warning',
          'Esta ação irá automaticamente remover todas as variáveis já adicionadas. Se deseja continuar basta efetuar a marcação do campo novamente.',
        )
      }
      setVariable(null)
      setCondition(null)
      setRules([])
      setGeneralRule(!generalRule)
    } else {
      setShowGeneralRuleWarning(false)
      setGeneralRule(!generalRule)
    }
  }

  const isValidForm =
    (classificationForm.fields?.code.value !== '' && rules.length > 0) ||
    (classificationForm.fields?.code.value !== '' && generalRule === true)

  return (
    <Stack
      width="960px"
      maxWidth={isSmallScreen ? '90vw' : '960px'}
      height="calc(100vh - 128px)"
      maxHeight="80vh"
      position="relative"
      borderTop="1px solid #13214E"
    >
      <Stack
        width="100%"
        height="100%"
        direction="row"
        gap="32px"
        marginTop="24px"
        sx={{
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
        paddingRight="8px"
      >
        {/* Left Content */}
        <Stack width="57%" height="100%" gap="24px">
          <Stack marginTop="8px" width="100%" direction="row" gap="16px">
            <BaseInput
              label="Código"
              type="text"
              iconEnd={<EditOutlinedIcon />}
              {...classificationForm.fields.code}
            />
            <BaseInput
              label="Nome da regra"
              type="text"
              iconEnd={<EditOutlinedIcon />}
              fullWidth={true}
              {...classificationForm.fields.name}
            />
          </Stack>
          <Stack width="100%" alignItems="center" direction="row" gap="16px">
            <Checkbox
              sx={{
                color: '#4865CC',
                '&.Mui-checked': {
                  color: '#4865CC',
                },
              }}
              checked={generalRule}
              onClick={handleChangeGeneralRule}
            />
            <Typography fontSize="16px">
              Toda e qualquer transação da empresa
            </Typography>
          </Stack>

          <Typography fontSize="16px">Selecione a variável</Typography>
          <Stack width="100%" direction="row" flexWrap="wrap" gap="16px">
            {variableButton(
              'Valor',
              CompanyClassificationRuleConditionalsVariableEnum.VALUE,
            )}
            {variableButton(
              'Data',
              CompanyClassificationRuleConditionalsVariableEnum.DATE,
            )}
            {variableButton(
              'Clientes e Fornecedores',
              CompanyClassificationRuleConditionalsVariableEnum.THIRD_PARTY,
            )}
            {variableButton(
              'Descrição',
              CompanyClassificationRuleConditionalsVariableEnum.DESCRIPTION,
            )}
          </Stack>

          <Typography fontSize="16px">Selecione a condição</Typography>
          <Stack width="100%" direction="row" flexWrap="wrap" gap="16px">
            {[
              CompanyClassificationRuleConditionalsVariableEnum.DESCRIPTION,
              CompanyClassificationRuleConditionalsVariableEnum.THIRD_PARTY,
              null,
            ].includes(variable) &&
              conditionButton(
                'Contém',
                CompanyClassificationRuleConditionalsConditionEnum.CONTAINS,
              )}
            {[
              CompanyClassificationRuleConditionalsVariableEnum.DESCRIPTION,
              CompanyClassificationRuleConditionalsVariableEnum.THIRD_PARTY,
              null,
            ].includes(variable) &&
              conditionButton(
                'Não contém',
                CompanyClassificationRuleConditionalsConditionEnum.NO_CONTAIN,
              )}
            {[
              CompanyClassificationRuleConditionalsVariableEnum.VALUE,
              null,
            ].includes(variable) &&
              conditionButton(
                'Maior que',
                CompanyClassificationRuleConditionalsConditionEnum.GREATER_THAN,
              )}
            {[
              CompanyClassificationRuleConditionalsVariableEnum.VALUE,
              null,
            ].includes(variable) &&
              conditionButton(
                'Menor que',
                CompanyClassificationRuleConditionalsConditionEnum.LESS_THAN,
              )}
            {[
              CompanyClassificationRuleConditionalsVariableEnum.DATE,
              CompanyClassificationRuleConditionalsVariableEnum.VALUE,
              CompanyClassificationRuleConditionalsVariableEnum.DESCRIPTION,
              CompanyClassificationRuleConditionalsVariableEnum.THIRD_PARTY,
              null,
            ].includes(variable) &&
              conditionButton(
                'Igual',
                CompanyClassificationRuleConditionalsConditionEnum.EQUAL,
              )}
            {variable &&
              variable ===
                CompanyClassificationRuleConditionalsVariableEnum.DATE &&
              conditionButton(
                'Entre',
                CompanyClassificationRuleConditionalsConditionEnum.BETWEEN,
              )}
          </Stack>

          {condition && variable && (
            <>
              <Typography fontSize="16px">Insira o nome da variável</Typography>

              {variable ===
                CompanyClassificationRuleConditionalsVariableEnum.THIRD_PARTY && (
                <Stack width="100%" gap="16px">
                  <BaseInput
                    label="Nome"
                    type="text"
                    fullWidth={true}
                    value={variableThirdPartyName}
                    setState={(e: string) => setVariableThirdPartyName(e)}
                    error=""
                    iconEnd={<EditOutlinedIcon />}
                  />
                  <Stack width="100%" direction="row" gap="16px">
                    <BaseInput
                      label="CNPJ"
                      type="text"
                      fullWidth={true}
                      value={variableThirdPartyDocument}
                      mask={'99.999.999/9999-99'}
                      setState={(e: string) => setVariableThirdPartyDocument(e)}
                      error=""
                      iconEnd={<EditOutlinedIcon />}
                    />
                    <Stack
                      onClick={handleAddNewVariable}
                      height="100%"
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        cursor: 'pointer',
                        color: theme.palette.primary.main,
                      }}
                    >
                      <SendOutlinedIcon />
                    </Stack>
                  </Stack>
                </Stack>
              )}

              {variable ===
                CompanyClassificationRuleConditionalsVariableEnum.DATE && (
                <Stack width="100%" gap="16px">
                  <Stack width="100%" direction="row" gap="16px">
                    <BaseInput
                      label="Dia"
                      type="number"
                      min={1}
                      max={31}
                      fullWidth={true}
                      value={variableDay !== '' ? variableDay : ''}
                      setState={(e: string) => {
                        setVariableDay(e)
                      }}
                      error=""
                    />
                    {condition ===
                      CompanyClassificationRuleConditionalsConditionEnum.BETWEEN && (
                      <BaseInput
                        label="Dia"
                        type="number"
                        min={1}
                        max={31}
                        fullWidth={true}
                        value={variableEndDay !== '' ? variableEndDay : ''}
                        setState={(e: string) => {
                          setVariableEndDay(e)
                        }}
                        error=""
                      />
                    )}
                    <Stack
                      onClick={handleAddNewVariable}
                      height="100%"
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        cursor: 'pointer',
                        color: theme.palette.primary.main,
                      }}
                    >
                      <SendOutlinedIcon />
                    </Stack>
                  </Stack>
                </Stack>
              )}

              {variable ===
                CompanyClassificationRuleConditionalsVariableEnum.DESCRIPTION && (
                <Stack width="100%" gap="16px">
                  <Stack width="100%" direction="row" gap="16px">
                    <BaseInput
                      label="Descrição"
                      type="text"
                      fullWidth={true}
                      value={variableDescription}
                      setState={(e: string) => setVariableDescription(e)}
                      error=""
                      iconEnd={<EditOutlinedIcon />}
                    />
                    <Stack
                      onClick={handleAddNewVariable}
                      height="100%"
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        cursor: 'pointer',
                        color: theme.palette.primary.main,
                      }}
                    >
                      <SendOutlinedIcon />
                    </Stack>
                  </Stack>
                </Stack>
              )}

              {variable ===
                CompanyClassificationRuleConditionalsVariableEnum.VALUE && (
                <Stack width="100%" gap="16px">
                  <Stack width="100%" direction="row" gap="16px">
                    <BaseInputMoney
                      label="Valor"
                      type="text"
                      fullWidth={true}
                      value={variableValue}
                      setState={(e: string) => setVariableValue(e)}
                      error=""
                      iconEnd={<EditOutlinedIcon />}
                    />
                    <Stack
                      onClick={handleAddNewVariable}
                      height="100%"
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        cursor: 'pointer',
                        color: theme.palette.primary.main,
                      }}
                    >
                      <SendOutlinedIcon />
                    </Stack>
                  </Stack>
                </Stack>
              )}
            </>
          )}

          <Stack
            width="100%"
            flexWrap="wrap"
            gap="24px"
            borderTop="1px dashed #CCCCCC"
          >
            <Stack marginTop="24px" width="100%" gap="16px" direction="row">
              <BaseInput
                label="Crédito"
                type="text"
                fullWidth={true}
                iconEnd={<EditOutlinedIcon />}
                {...classificationForm.fields.credit}
              />
              <BaseInput
                label="Débito"
                type="text"
                iconEnd={<EditOutlinedIcon />}
                fullWidth={true}
                {...classificationForm.fields.debit}
              />
            </Stack>
            <Stack width="100%" gap="16px" direction="row">
              <Stack width="50%">
                <BaseInput
                  label="Categoria financeira"
                  type="text"
                  fullWidth={true}
                  iconEnd={<EditOutlinedIcon />}
                  {...classificationForm.fields.financialCategory}
                />
              </Stack>
              <Stack
                width="50%"
                justifyContent="center"
                alignItems="center"
                borderRadius="100px"
                border={`1px solid ${theme.palette.primary.main}`}
              >
                <Stack width="90%" borderRadius="100px">
                  <BaseDropDownInput
                    options={HistoricVariables}
                    buttonLabel="Histórico"
                    showInternalLabel
                    {...classificationForm.fields.historic}
                    value={classificationForm?.fields?.historic?.value ?? []}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        {/* Right Content */}
        <Stack height="100%" width="43%" maxWidth="420px">
          <Stack
            height="100%"
            gap="16px"
            borderRadius="16px"
            padding="24px"
            flexWrap="wrap"
            sx={{ backgroundColor: '#F4F8FA' }}
          >
            {rules &&
              rules.map((rule, index) => {
                const conditionDescription =
                  CompanyClassificationRuleConditionalsConditionEnumUtil.toString(
                    rule.condition,
                  )
                let variableContent = ''

                if (
                  rule.variable ===
                  CompanyClassificationRuleConditionalsVariableEnum.THIRD_PARTY
                ) {
                  variableContent = rule.data.thirdParty?.name ?? ''
                }

                if (
                  rule.variable ===
                  CompanyClassificationRuleConditionalsVariableEnum.DATE
                ) {
                  variableContent = `Dia ${rule.data.date?.init}`
                  if (rule.data.date?.end !== rule.data.date?.init) {
                    variableContent += ` e ${rule.data.date?.end}`
                  }
                }

                if (
                  rule.variable ===
                  CompanyClassificationRuleConditionalsVariableEnum.VALUE
                ) {
                  variableContent = rule.data.value?.value
                    ? `${rule.data.value?.value}`
                    : 'R$ 0,00'
                }

                if (
                  rule.variable ===
                  CompanyClassificationRuleConditionalsVariableEnum.DESCRIPTION
                ) {
                  variableContent = rule.data.description?.description ?? ''
                }

                return (
                  <Stack key={index} width="100%" gap="16px" direction="row">
                    <Stack
                      padding="8px 16px 8px 16px"
                      gap="8px"
                      borderRadius="16px"
                      color="#FFFFFF"
                      sx={{
                        backgroundColor: '#5ED1A2',
                      }}
                    >
                      {conditionDescription}
                    </Stack>

                    <Stack
                      padding="8px 16px 8px 16px"
                      gap="8px"
                      borderRadius="16px"
                      color="#FFFFFF"
                      sx={{
                        backgroundColor: '#1E3466',
                      }}
                    >
                      {variableContent}
                    </Stack>

                    <Stack
                      height="100%"
                      alignItems="center"
                      justifyContent="center"
                      color={theme.palette.primary.main}
                      onClick={() => handleDeleteVariable(rule)}
                      sx={{ cursor: 'pointer' }}
                    >
                      <DeleteOutlineOutlinedIcon />
                    </Stack>
                  </Stack>
                )
              })}
          </Stack>

          <Stack
            width="100%"
            gap="24px"
            direction="row"
            justifyContent="end"
            alignItems="end"
          >
            <Button
              onClick={handleCloseModal}
              variant="outlined"
              sx={{ width: '120px', marginTop: '24px' }}
            >
              Cancelar
            </Button>
            <Button
              onClick={handleValidate}
              variant="contained"
              disabled={!isValidForm || isLoading}
              sx={{
                width: '140px',
                marginTop: '24px',
                '&:disabled': {
                  borderColor: 'transparent',
                },
              }}
            >
              Criar regra
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}
