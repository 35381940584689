import FileUploadIcon from '@mui/icons-material/FileUpload'
import { Button, CircularProgress, Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BaseInput } from 'src/component/base-component/base-input'
import { BasePageTitle } from 'src/component/base-component/base-page-title'
import { ToastFullContext } from 'src/component/base-component/base-snackbar'
import { DefaultHeader } from 'src/component/header/header'
import { ImportFileModal } from 'src/component/modal/modal-import-file'
import { ModalFullContext } from 'src/component/modal/modal-provider'
import { PlanList } from 'src/component/plan/list-plans'
import { VerticalStepper } from 'src/component/vertical-stepper/vertical-stepper'
import { useCompaniesMassImportContext } from 'src/shared/context/company-mass-import-provider'
import { CreateForm } from 'src/shared/util/form/form-util'
import { CreateCompanyUseCase } from 'src/usecase/company/usecase-company-form-create'
import { CreateCompanyValidateFileUseCase } from 'src/usecase/company/usecase-company-validate-file'
import BlueHeader from '../../assets/background/header-blue.png'
import { MockPlans } from './mock'
import { ListCompanyPlansUseCase } from 'src/usecase/company/usecase-company-plans-list'
import { ICompanyPlan } from 'src/service/service-company'

interface CreateCompanyPageProps {
  useCase: CreateCompanyUseCase
  masImportUseCase: CreateCompanyValidateFileUseCase
  listCompanyPlansUseCase: ListCompanyPlansUseCase
}

export function CreateCompanyPage(props: CreateCompanyPageProps): any {
  const navigate = useNavigate()
  const { setCompanies } = useCompaniesMassImportContext()
  const { ShowModal, HideModal } = ModalFullContext()
  const { ShowToast } = ToastFullContext()

  const [currentStep, setCurrentStep] = useState(0)
  const [plans, setPlans] = useState<ICompanyPlan[]>([])
  const [loading, setLoading] = useState(false)

  const registerForm = CreateForm({
    fields: ['name', 'cnpj'],
    validation: props.useCase.validation,
  })

  const handleListPlansUseCase = async () => {
    try {
      setLoading(true)
      const plansResult = await props.listCompanyPlansUseCase.handle()

      if (plansResult.isFailure) {
        setLoading(false)
        return ShowToast(
          'error',
          'Ocorreu um erro ao listar os planos de contratação. Tente novamente mais tarde.',
        )
      }

      const plansData = plansResult.getValue()
      setPlans(plansData as ICompanyPlan[])
      setLoading(false)
    } catch (error) {
      setLoading(false)
      ShowToast(
        'error',
        'Ocorreu um erro ao listar os planos de contratação. Tente novamente mais tarde.',
      )
    }
  }

  useEffect(() => {
    handleListPlansUseCase()
  }, [])

  const handleCreateUsecase = async () => {
    try {
      setLoading(true)

      const usecaseResult = await props.useCase.handle({
        name: registerForm.fields.name.value,
        cnpj: registerForm.fields.cnpj.value?.replace(/[^0-9]/g, ''),
      })

      setLoading(false)

      if (usecaseResult.isFailure) {
        switch ((usecaseResult.error as any)?.type) {
          case 'CompanyInvalidCnpjError':
            ShowToast('error', 'O CNPJ informado é inválido.')
            break

          case 'CompanyCnpjAlreadyExistsError':
            ShowToast('error', 'O CNPJ informado já está cadastrado.')
            break

          default:
            ShowToast(
              'error',
              'Ocorreu um erro ao cadastrar. Verifique os campos ou tente novamente mais tarde.',
            )
            break
        }
      } else {
        ShowToast('success', 'Empresa criada com sucesso.')
        navigate('/home')
      }
    } catch (error) {
      setLoading(false)
      ShowToast(
        'error',
        'Ocorreu um erro ao cadastrar. Tente novamente mais tarde.',
      )
    }
  }

  const handleCreate = async () => {
    try {
      const validationResult = await registerForm.validate()

      if (validationResult.isFailure) {
        ShowToast('error', 'Verifique todos os campos')
        return
      }

      return handleCreateUsecase()
    } catch (error) {
      ShowToast(
        'error',
        'Ocorreu um erro ao cadastrar. Tente novamente mais tarde.',
      )
    }
  }

  const handleCancel = () => {
    navigate('/home')
  }

  const handleUploadFile = async (file: File) => {
    try {
      const companiesFromFile = await props.masImportUseCase.handle(file)

      if (companiesFromFile.isFailure) {
        return ShowToast(
          'error',
          'Ocorreu um erro ao processar o arquivo. Tente novamente mais tarde.',
        )
      }

      const companiesData = companiesFromFile.getValue()
      const mappedData = companiesData!.map((item, index) => {
        return { id: index, ...item }
      })

      setCompanies(mappedData)
      HideModal()
      navigate('/company/new/import')
    } catch (error) {
      ShowToast(
        'error',
        'Ocorreu um erro ao cadastrar. Tente novamente mais tarde.',
      )
    }
  }

  const handleImportFile = () => {
    ShowModal({
      content: (
        <ImportFileModal
          instructions={[
            '• Datas devem estar no seguinte formato DD/MM/AAAA',
            '• Caso uma conta contábil/bancária não seja encontrada na plataforma, ela não será criada automaticamente',
            '• As células não devem conter fórmulas',
            '• Valores devem respeitar a pontuação correta',
            '• , representa centavos',
            '• Não utilizar . para representar mil',
            '• Colulas * representam obrigatoriedade de preenchimento. Caso não sejam preenchidas a linha inteira será desconsiderada',
            '• CPF/CNPJ não devem conter pontuações nem caracteres especiais',
          ]}
          handle={handleUploadFile}
          allowedExtensions={['csv']}
          template={{
            url: '/csv-example/create-company.csv',
            filename: 'create-company.csv',
          }}
        />
      ),
      closeButton: false,
      title: 'Importar planilha excel',
    })
  }

  return (
    <Stack width="100%" height="100%" sx={{ backgroundColor: '#F4F8FA' }}>
      <DefaultHeader
        containerStyle={{
          backgroundImage: `url(${BlueHeader})`,
          alignItems: 'flex-start',
          paddingTop: '32px',
          zIndex: 9,
          position: 'relative',
        }}
        breadcumbItems={[
          { title: 'Painel multiempresas', navigateTo: '/home' },
          { title: 'Cadastro de empresa' },
        ]}
        buttons={
          <Stack direction="row" alignItems="center">
            <Button
              fullWidth={false}
              variant="outlined"
              onClick={() => navigate('/home')}
              sx={{
                width: '284px',
                height: '40px',
                color: '#fff',
                borderColor: '#fff',
                padding: 0,
                fontSize: '14px',
              }}
            >
              Voltar para o painel multiempresa
            </Button>
          </Stack>
        }
      />

      <Stack
        width="100%"
        height="calc(100% - 125px)"
        direction="row"
        gap="32px"
        overflow="hidden"
      >
        <Stack width="80%" height="100%" padding="24px">
          <Stack width="100%" justifyContent="space-between" direction="row">
            <BasePageTitle color="#1E3466" text="Cadastro de empresas" />
            <Button
              startIcon={<FileUploadIcon />}
              fullWidth={false}
              variant="contained"
              onClick={handleImportFile}
              sx={{
                padding: '12px 24px 12px 16px',
                gap: '8px',
                width: '220px',
              }}
            >
              Importar planilha
            </Button>
          </Stack>
          <Stack width="100%" height="100%" gap="32px" direction="column">
            <Stack
              width="100%"
              height="85%"
              gap="24px"
              paddingTop="24px"
              paddingRight="12px"
              sx={{ overflowX: 'hidden', overflowY: 'auto' }}
            >
              <Stack width="100%" direction="row" gap="24px">
                <BaseInput
                  label="Nome/Razão social"
                  fullWidth
                  {...registerForm.fields.name}
                />
                <BaseInput
                  label="CNPJ"
                  fullWidth
                  {...registerForm.fields.cnpj}
                  mask={'99.999.999/9999-99'}
                />
              </Stack>

              <Stack paddingRight="12px" width="100%" gap="32px">
                <BasePageTitle color="#000000" text="Planos" />
                <PlanList plans={plans} />
              </Stack>
            </Stack>
            <Stack
              width="100%"
              height="16%"
              justifyContent="flex-start"
              alignItems="flex-end"
              marginBottom="32px"
            >
              {!loading && (
                <Stack gap="24px" direction="row">
                  <Button
                    variant="outlined"
                    onClick={handleCancel}
                    sx={{ width: '130px' }}
                  >
                    Cancelar
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleCreate}
                    sx={{ width: '130px' }}
                  >
                    Continuar
                  </Button>
                </Stack>
              )}
              {loading && <CircularProgress />}
            </Stack>
          </Stack>
        </Stack>
        <Stack width="20%" height="100%" alignItems="center">
          <VerticalStepper
            currentStep={currentStep}
            setCurrent={setCurrentStep}
            steps={[
              { title: 'Plano' },
              { title: 'Faturamento' },
              { title: 'Pagamento' },
            ]}
          />
        </Stack>
      </Stack>
    </Stack>
  )
}
