import {
  CompanyApiRemoteService,
  CompanyDebitRequest,
  ICompanyPlan,
} from 'src/service/service-company'
import { Result } from 'src/shared/protocol/protoco-result'
import { IUsecase } from 'src/shared/protocol/protocol-usecase'

export class ListCompanyPlansUseCase implements IUsecase<null, ICompanyPlan[]> {
  constructor(private companyApi: CompanyApiRemoteService) {}

  public async handle(): Promise<Result<ICompanyPlan[]>> {
    try {
      const response = await this.companyApi.getCompanyPlans()

      if (response.isFailure) {
        return Result.fail(
          new Error('Erro ao recuperar os planos de contratação.'),
        )
      }

      const result = response.getValue()

      if (result === null) {
        return Result.fail(
          new Error('Erro ao recuperar os planos de contratação.'),
        )
      }

      return Result.ok(result)
    } catch (error: any) {
      return Result.fail(error)
    }
  }
}
