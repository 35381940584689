export function getInitials(name?: string) {
  if (!name) return ''

  const partes = name?.split(' ')
  if (partes.length === 1) {
    return partes[0][0]
  } else {
    return partes[0][0] + partes[1][0]
  }
}

export function getFirstName(name: string) {
  if (!name) return ''

  const splittedName = name.split(' ')

  return `${splittedName[0]}`
}

export function getFormattedUserName(name?: string) {
  if (!name) return ''

  const partes = name.split(' ')
  if (partes.length === 1) {
    return partes[0]
  } else {
    return `${partes[0]} ` + `${partes[1]}`
  }
}
