import { Button, Stack, Typography } from '@mui/material'
import { PlanIcon } from '../icons/plan'
// import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
// import HighlightOffIcon from '@mui/icons-material/HighlightOff'

export type PlanCardProps = {
  id: string
  title: string
  selectionButtonAction?: () => void
}

export const PlanCard = ({
  id,
  title,
  selectionButtonAction,
}: PlanCardProps) => {
  return (
    <Stack
      width="100%"
      maxWidth="calc(50% - 24px - 24px - 24px)"
      height="580px"
      borderRadius="8px"
      border="1px solid #B9BFC7"
      padding="24px"
    >
      <Stack
        width="100%"
        justifyContent="space-between"
        direction="row"
        borderBottom="1px solid #B9BFC7"
      >
        <Typography
          display="flex"
          key={`plan-card-${title}`}
          fontWeight="700"
          fontSize="24px"
          gap="12px"
          justifyContent="center"
          alignItems="center"
        >
          <PlanIcon />
          {title}
        </Typography>

        <Stack
          justifyContent="center"
          alignItems="flex-end"
          paddingBottom="8px"
        >
          <Typography
            display="flex"
            key={`plan-card-price-${id}`}
            fontWeight="700"
            fontSize="24px"
            gap="12px"
            justifyContent="center"
            alignItems="center"
          ></Typography>
          <Typography
            display="flex"
            key={`plan-card-subtitle-${id}`}
            fontWeight="400"
            fontSize="14px"
            gap="12px"
            justifyContent="center"
            alignItems="center"
          >
            +R$ 13,90 por CNPJ acima do 11º
          </Typography>
        </Stack>
      </Stack>
      {/* <Stack
        gap="16px"
        marginTop="16px"
        width="100%"
        justifyContent="flex-start"
      >
        {planOptions.map((option) => {
          return (
            <Typography
              display="flex"
              width="100%"
              key={`plan-card-${subTitle}`}
              fontWeight="400"
              fontSize="14px"
              gap="12px"
              justifyContent="flex-start"
              alignItems="flex-start"
              color="#1E1E1E"
              sx={{
                '.MuiSvgIcon-root': {
                  fill: !option.active ? '#D52020' : '#057D88',
                  color: !option.active ? '#D52020' : '#057D88',
                },
              }}
            >
              {option.active && <CheckCircleOutlineIcon />}
              {!option.active && <HighlightOffIcon />}
              {option.option}
            </Typography>
          )
        })}
      </Stack> */}

      <Stack
        width="100%"
        justifyContent="center"
        alignItems="center"
        onClick={() => selectionButtonAction && selectionButtonAction()}
      >
        <Button variant="outlined" sx={{ width: '220px', marginTop: '12px' }}>
          Selecionar plano
        </Button>
      </Stack>
    </Stack>
  )
}
