import { FileDownloadOutlined, RefreshOutlined } from '@mui/icons-material'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import { CalendarIcon } from '@mui/x-date-pickers-pro'
import {
  format,
  startOfMonth,
  endOfMonth,
  subMonths,
  startOfDay,
  subDays,
} from 'date-fns'
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { BankAccountCard } from 'src/component/bank/account-card'
import { Logo } from 'src/component/base-component/base-logo-component'
import { BasePageSubtitle } from 'src/component/base-component/base-page-subtitle'
import { ToastFullContext } from 'src/component/base-component/base-snackbar'
import { CascadeButton } from 'src/component/buttons/cascade-button'
import { CardResume } from 'src/component/card/card-resume'
import { CashFlow } from 'src/component/financial/cash-flow'
import { Extract, ExtractProps } from 'src/component/financial/extract'
import { DefaultHeader } from 'src/component/header/header'
import { ClockIcon } from 'src/component/icons/clock-icon'
import { NotificationBellIcon } from 'src/component/icons/notification-bell'
import { ScreenIcon } from 'src/component/icons/screen'
import { SettingsIcon } from 'src/component/icons/settings'
import { ImportExtractSuccessModal } from 'src/component/modal/modal-import-extract-success'
import { ImportFileModal } from 'src/component/modal/modal-import-file'
import { ModalFullContext } from 'src/component/modal/modal-provider'
import { ModalReportsExport } from 'src/component/modal/modal-reports-export'
import { ModalSelectPriod } from 'src/component/modal/modal-select-period'
import { ModalSelectSystemicAccount } from 'src/component/modal/modal-select-systemic-account'
import { UserFullContext } from 'src/context/context-user'
import { BankAccountTransferMethodEnum } from 'src/service/service-bank'
import { formatMoney } from 'src/shared/util/formatter/formatter-utils'
import { AccountGetCSVColumnsFileUseCase } from 'src/usecase/bank-account/usecase-account-get-csv-columns'
import { AccountImportOfxFileUseCase } from 'src/usecase/bank-account/usecase-account-upload-file-ofx'
import { ListUserCompaniesAccountsUseCase } from 'src/usecase/bank-account/usecase-user-companies-list'
import { ListCashFlowUseCase } from 'src/usecase/cashflow/list-cashflow'
import { CreateCompanyUsersUseCase } from 'src/usecase/company/usecase-company-add-users'
import { LeaveCompanyUseCase } from 'src/usecase/company/usecase-company-leave'
import { ListCompanyUseCase } from 'src/usecase/company/usecase-company-list'
import { SyncAccountOpenFinanceUseCase } from 'src/usecase/company/usecase-refresh-open-finance'
import { ListTransfersUseCase } from 'src/usecase/transfers/list-transfers'
import { UserListUseCase } from 'src/usecase/user/usecase-user-list'
import { generateOFX } from 'src/utils/generateCustomOFX'
import { generateCustomPDF } from 'src/utils/generateCustomPDF'
import { generateXLS } from 'src/utils/generateXLS'
import BlueHeader from '../../assets/background/header-blue.png'
import { Account } from '../company-reports/page-company-reports'
import { useCashFlow } from '../company-reports/useCashFlow'
import { useTransfers } from '../company-reports/useTransfers'
import { useUserAccount } from '../systemic-accounts/useUserAccount'

interface CompanyDetailsProps {
  listCompaniesUseCase: ListCompanyUseCase
  addUserToCompanyUseCase: CreateCompanyUsersUseCase
  listUsersUseCase: UserListUseCase
  leaveCompanyUseCase: LeaveCompanyUseCase
  userAccountsCompaniesUseCase: ListUserCompaniesAccountsUseCase
  listUserCompaniesAccountsUseCase: ListUserCompaniesAccountsUseCase
  listTransfersUseCase: ListTransfersUseCase
  listCashFlowUseCase: ListCashFlowUseCase
  getCsvFileColumnsUseCase: AccountGetCSVColumnsFileUseCase
  uploadOFXFile: AccountImportOfxFileUseCase
  syncAccountOpenFinanceUseCase: SyncAccountOpenFinanceUseCase
}

export function CompanyDetailsPage(props: CompanyDetailsProps): any {
  const { companyId } = useParams()
  const navigate = useNavigate()
  const { ShowToast } = ToastFullContext()
  const { ShowModal, HideModal } = ModalFullContext()
  const [containerWidth, setContainerWidth] = useState(0)
  const ContainerRef = useRef<HTMLDivElement>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [initialDate, setInitialDate] = useState<Date | undefined>(
    startOfMonth(subMonths(new Date(), 2)),
  )
  const [finalDate, setFinalDate] = useState<Date | undefined>(
    endOfMonth(startOfDay(new Date())),
  )

  const { setCurrentCompanyId, GetCompanyData, currentCompanyId } =
    UserFullContext()

  const [companyAccounts, setCompanyAccounts] = useState<Account[]>([])
  const [accountsSelected, setAccountsSelected] = useState<string[]>([])
  const [filterDates, setFilterDates] = useState<string[] | undefined>([
    startOfMonth(subMonths(new Date(), 2)).toString(),
    subDays(endOfMonth(startOfDay(new Date())), 1).toString(),
  ])
  const [extract, setExtract] = useState({} as ExtractProps)

  const {
    handleListUserCompaniesAccounts,
    userCompaniesAccounts: CompanyBankAccountsFiltered,
  } = useUserAccount(props.listUserCompaniesAccountsUseCase)

  const { handleListTransfers, transfers, balance } = useTransfers(
    props.listTransfersUseCase,
  )

  const {
    handleListCashFlow,
    cashflowConsolidate,
    variationData,
    minVariation,
    maxVariation,
    categoriesVariations,
  } = useCashFlow(props.listCashFlowUseCase)

  useEffect(() => {
    if (ContainerRef.current) {
      setContainerWidth(ContainerRef.current.offsetWidth)
    }
  }, [])

  useEffect(() => {
    setIsLoading(true)
    if (companyId) {
      handleListUserCompaniesAccounts([companyId])
    }
    setIsLoading(false)
  }, [companyId])

  useEffect(() => {
    if (!CompanyBankAccountsFiltered?.length) {
      setCompanyAccounts([])
      setAccountsSelected([])
    }

    const normalize: Account[] = CompanyBankAccountsFiltered.map((account) => {
      return {
        id: account.id,
        name: account.name,
        amount: account.balance,
        agency: account.agencyNumber,
        account: account.accountNumber,
        bankName: account.bank.split('-')[1]?.trim(),
        bankLogo: account.bankLogo,
        openFinance: account.openFinance,
      }
    })

    setCompanyAccounts(normalize)
    setAccountsSelected(normalize.map((account) => account.id))
  }, [CompanyBankAccountsFiltered, companyId])

  useEffect(() => {
    const normalizeTransfers = transfers.map((transfer) => ({
      ...transfer,
      amountFormatted: formatMoney(Number(transfer.amount)),
      date: format(new Date(transfer.date), 'dd/MM/yyyy'),
    }))
    const extractBody: ExtractProps = {
      all: normalizeTransfers,
      income: normalizeTransfers?.filter(
        (transfer) => transfer.method === BankAccountTransferMethodEnum.CREDITO,
      ),
      outcome: normalizeTransfers?.filter(
        (transfer) => transfer.method === BankAccountTransferMethodEnum.DEBITO,
      ),
    }

    setExtract(extractBody)
  }, [transfers])

  const handleListCompanyAccounts = async () => {
    try {
      //   handleListCompanyAccountsUsecase()
    } catch (error) {
      ShowToast(
        'error',
        'Ocorreu um erro ao buscar as contas bancárias. Tente novamente mais tarde.',
      )
    }
  }

  useEffect(() => {
    setIsLoading(true)
    setCurrentCompanyId(companyId ?? '')
    handleListCompanyAccounts()
    setIsLoading(false)
  }, [companyId])

  useEffect(() => {
    setIsLoading(true)
    if (companyId) {
      handleListCashFlow({
        companiesIds: [companyId],
        accountIds: accountsSelected,
        date: filterDates,
        accountRequired: true,
      })
    }
    setIsLoading(false)
  }, [companyId, accountsSelected, filterDates])

  const handleSelectAccount = (accountId: string) => {
    if (!accountsSelected.includes(accountId)) {
      setAccountsSelected([...accountsSelected, accountId])
    } else {
      setAccountsSelected(
        accountsSelected.filter((account) => account !== accountId),
      )
    }
  }

  useEffect(() => {
    setIsLoading(true)
    if (filterDates && filterDates.length > 0) {
      handleListTransfers({
        accountsIds: accountsSelected,
        date: filterDates,
      })
    } else {
      handleListTransfers({ accountsIds: accountsSelected })
    }
    setIsLoading(false)
  }, [accountsSelected, filterDates])

  const handleFilterPeriod = async (dates: string[]) => {
    if (dates.length > 0) {
      await handleListTransfers({
        accountsIds: accountsSelected,
        date: dates,
      })
      setFilterDates(dates)
    } else {
      await handleListTransfers({ accountsIds: accountsSelected })
      setInitialDate(undefined)
      setFinalDate(undefined)
      setFilterDates(undefined)
    }
    HideModal()
  }

  const handleRefresh = async () => {
    if (accountsSelected.length === 0) {
      return ShowToast(
        'error',
        'Selecione ao menos uma conta que deseja atualizar.',
      )
    }
    await Promise.all(
      accountsSelected.map(async (selectedAccount) => {
        try {
          setIsLoading(true)
          await props.syncAccountOpenFinanceUseCase.handle({
            bankAccountId: selectedAccount,
            companyId: companyId as string,
          })
        } catch (error) {
          //
        }
        return selectedAccount
      }),
    )

    if (filterDates && filterDates.length > 0) {
      handleListTransfers({
        accountsIds: accountsSelected,
        date: filterDates,
      })
    } else {
      handleListTransfers({ accountsIds: accountsSelected })
    }
    handleListCashFlow({
      companiesIds: [companyId as string],
      accountIds: accountsSelected,
      date: filterDates,
      accountRequired: true,
    })
    setIsLoading(false)
  }

  const handleOpenPeriodModal = () => {
    ShowModal({
      content: (
        <ModalSelectPriod
          initialDate={initialDate}
          finalDate={finalDate}
          setFinalDate={setFinalDate}
          setInitialDate={setInitialDate}
          onSelect={handleFilterPeriod}
        />
      ),
      title: 'Período',
      closeOnBackgroundClick: false,
      closeButton: false,
    })
  }

  const handleValidateCSVFileColumns = async (
    file: File,
    accountId: string,
  ) => {
    const columnsData = await props.getCsvFileColumnsUseCase.handle({
      file,
      accountId,
      companyId: companyId as string,
    })

    HideModal()
    if (columnsData.isFailure) {
      return ShowToast(
        'error',
        'Ocorreu um erro ao processar o arquivo. Tente novamente mais tarde.',
      )
    }

    const columns = await columnsData.getValue()
    if (columns?.columns.length === 0) {
      return ShowToast(
        'warning',
        'O arquivo selecionado não possui nenhuma coluna válida.',
      )
    }

    return navigate(
      `/company/${companyId}/account/${accountId}/import/extract/csv`,
      {
        state: { fileColumns: columns?.columns, file },
      },
    )
  }

  const handleUploadOFXFile = async (file: File, accountId: string) => {
    try {
      const ofxFile = await props.uploadOFXFile.handle({
        file,
        companyId: companyId as string,
        accountId: accountId as string,
      })

      if (ofxFile.isFailure) {
        HideModal()
        return ShowToast(
          'error',
          'Ocorreu um erro ao processar o arquivo. Tente novamente mais tarde.',
        )
      }

      const ofxResult = ofxFile.getValue()
      HideModal()

      if (ofxResult) {
        ShowModal({
          content: (
            <ImportExtractSuccessModal
              finalBalance={ofxResult.amount}
              totalFailed={ofxResult?.totalFailed}
              totalImported={ofxResult?.totalImported}
              totalRegister={ofxResult?.totalRegister}
            />
          ),
          title: '',
        })
      } else {
        ShowToast(
          'warning',
          'Arquivo importado com sucesso, porém não foi possível recuperar os dados de sucessos / falhas ao importar.',
        )
      }
    } catch (error) {
      ShowToast(
        'error',
        'Ocorreu um erro ao importar o arquivo. Tente novamente mais tarde.',
      )
    }
  }

  const handleUploadConfirmoOFXBalance = async (
    file: File,
    accountId: string,
  ) => {
    handleUploadOFXFile(file, accountId)
  }

  const handleImportModalHandle = (accountId: string, importType: string) => {
    HideModal()
    if (importType === 'csv') {
      ShowModal({
        content: (
          <ImportFileModal
            showInstructions={false}
            controlRegisterId={accountId}
            handle={handleValidateCSVFileColumns}
            allowedExtensions={['csv']}
          />
        ),
        title: 'Importar planilha excel',
        closeButton: false,
      })
    } else {
      ShowModal({
        content: (
          <ImportFileModal
            showInstructions={false}
            controlRegisterId={accountId}
            handle={handleUploadConfirmoOFXBalance}
            allowedExtensions={['ofx']}
          />
        ),
        title: 'Importar OFX',
        closeButton: false,
      })
    }
  }

  const handleOpenSelectAccountListExtractModal = () => {
    const normalizeAccounts = companyAccounts
      ?.filter((filter) => !filter.openFinance)
      ?.map((account) => {
        return {
          label: account.name,
          value: account.id,
          component: (
            <Stack direction="row" alignItems="center" padding="0 20px">
              <Logo
                name={account.bankName}
                width={32}
                height={32}
                logo={account.bankLogo}
              />

              <Typography marginLeft="15px" fontSize={14} fontWeight={400}>
                {account.name}
              </Typography>
            </Stack>
          ),
        }
      })

    ShowModal({
      content: (
        <ModalSelectSystemicAccount
          onSelect={(e) => {
            HideModal()
            navigate(`/company/${companyId}/account/${e}/extract/list`)
          }}
          type="delete"
          options={normalizeAccounts}
        />
      ),
      title: 'Selecionar conta',
      closeOnBackgroundClick: false,
    })
  }

  const handleOpenSystemicAccountModal = (type: 'ofx' | 'csv') => {
    const normalizeAccounts = companyAccounts
      ?.filter((filter) => !filter.openFinance)
      ?.map((account) => {
        return {
          label: account.name,
          value: account.id,
          component: (
            <Stack direction="row" alignItems="center" padding="0 20px">
              <Logo
                name={account.bankName}
                width={32}
                height={32}
                logo={account.bankLogo}
              />

              <Typography marginLeft="15px" fontSize={14} fontWeight={400}>
                {account.name}
              </Typography>
            </Stack>
          ),
        }
      })

    ShowModal({
      content: (
        <ModalSelectSystemicAccount
          onSelect={(e) => handleImportModalHandle(e, type)}
          options={normalizeAccounts}
        />
      ),
      title: 'Selecionar conta',
      closeOnBackgroundClick: false,
    })
  }

  const handleExport = async (selectedFormat: string, fullContent: boolean) => {
    if (!extract) {
      return ShowToast(
        'warning',
        'Nenhum registro disponível para ser impresso. ',
      )
    }

    if (selectedFormat === 'OFX') {
      if (accountsSelected?.length > 1) {
        ShowToast(
          'error',
          'Somente uma conta pode ser selecionada para este método de exportação.',
        )
        return
      }
      const ofxContent = await generateOFX(transfers, balance)
      const blob = new Blob([ofxContent], { type: 'application/ofx' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = 'transactions.ofx'
      link.click()
      URL.revokeObjectURL(url)
      return
    }

    const columns = [
      { header: 'Data', dataKey: 'date' },
      { header: 'Descrição', dataKey: 'description' },
      { header: 'Tipo', dataKey: 'type' },
      { header: 'Valor', dataKey: 'value' },
    ]

    const data = extract.all.map((extractItem) => {
      return {
        date: extractItem.date,
        description: extractItem.transferCompleteDescription,
        type:
          extractItem.method === BankAccountTransferMethodEnum.CREDITO
            ? 'Crédito'
            : 'Débito',
        value: extractItem.amountFormatted,
      }
    })
    if (selectedFormat === 'PDF') {
      const title = 'Visão Financeira'
      const subtitle = 'Extrato de Lançamentos'

      if (fullContent) {
        const input = document.getElementById('cashflowContent') as HTMLElement
        await generateCustomPDF({
          title,
          subtitle,
          columns,
          data,
          contentDiv: input,
        })
      } else {
        await generateCustomPDF({ title, subtitle, columns, data })
      }
    }
    if (selectedFormat === 'Excel') {
      const sheets = {
        sheetName: 'Extrato de Lançamentos',
        columns: columns.map((column) => column.header),
        data: data.map((item) => {
          return [item.date, item.description, item.type, item.value]
        }),
      }

      await generateXLS([sheets], 'Visão Financeira.xls')
    }
  }

  return (
    <Stack
      sx={{ backgroundColor: '#F4F8FA', overflowX: 'hidden', flex: 1 }}
      ref={ContainerRef}
    >
      <DefaultHeader
        containerStyle={{
          height: '108px',
          backgroundImage: `url(${BlueHeader})`,
          alignItems: 'flex-start',
          paddingTop: '32px',
          zIndex: 9,
          position: 'relative',
        }}
        leftComponent={
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            paddingLeft="64px"
          >
            <Typography fontSize="24px" fontWeight={700} color="#fff">
              {GetCompanyData().name}
            </Typography>
            <Stack
              justifyContent="center"
              alignItems="center"
              width="64px"
              height="32px"
              fontWeight={700}
              fontSize="12px"
              borderRadius="16px"
              marginTop="5px"
              marginLeft="20px"
              sx={{ backgroundColor: '#03B575', color: '#F4F8FA' }}
            >
              Ativo
            </Stack>
          </Box>
        }
        buttons={
          <Stack direction="row" alignItems="center">
            <Button
              fullWidth={false}
              variant="outlined"
              onClick={() => navigate('/home')}
              sx={{
                width: '284px',
                height: '40px',
                color: '#fff',
                borderColor: '#fff',
                padding: 0,
                fontSize: '14px',
              }}
            >
              Voltar para o painel multiempresa
            </Button>

            <IconButton
              sx={{
                backgroundColor: '#ffffff',
                color: '#4865CC',
                marginLeft: '24px',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                width: '40px',
                height: '40px',
                padding: 0,
              }}
              onClick={() => console.log('empty')}
            >
              <NotificationBellIcon />
            </IconButton>

            <IconButton
              sx={{
                backgroundColor: '#ffffff',
                color: '#4865CC',
                marginLeft: '15px',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                width: '40px',
                height: '40px',
                padding: 0,
              }}
              onClick={() => navigate(`/company/${currentCompanyId}/edit`)}
            >
              <SettingsIcon />
            </IconButton>
          </Stack>
        }
      />

      <Stack
        id="fullContentPDFToPrint"
        height="100%"
        width={`calc(${containerWidth}px - 80px)`}
        padding="32px"
        gap="48px"
        position="relative"
        zIndex="999"
        marginTop="-80px"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap="32px"
        >
          <CardResume
            title="Lançamentos automáticos"
            icon={<ScreenIcon />}
            time="Este mês"
            value="56.765"
            variationType="up"
            variation="12%"
          />
          <CardResume
            title="Economia de tempo"
            icon={<ClockIcon />}
            time="Este mês"
            value="102h"
            variationType="down"
            variation="1%"
          />
        </Box>

        <Stack gap="20px" mt="-10px">
          <Box display="flex" justifyContent="space-between">
            <BasePageSubtitle
              color="#1E3466"
              text="Visão financeira"
              style={{ fontSize: '32px', fontWeight: 700 }}
            />

            <Box display="flex" alignItems="center" gap="24px">
              <Button
                variant="outlined"
                endIcon={<CalendarIcon />}
                sx={{ minWidth: '123px', height: '40px' }}
                onClick={() => handleOpenPeriodModal()}
              >
                <Typography
                  fontWeight="500"
                  fontSize="14px"
                  lineHeight="16.41px"
                >
                  Período
                </Typography>
              </Button>

              <Button
                variant="outlined"
                startIcon={<RefreshOutlined />}
                sx={{ minWidth: '123px', height: '40px' }}
                onClick={() => handleRefresh()}
              >
                <Typography
                  fontWeight="500"
                  fontSize="14px"
                  lineHeight="16.41px"
                >
                  Atualizar
                </Typography>
              </Button>

              <CascadeButton
                title="Extrato"
                menuStyle={{ marginLeft: '-160px' }}
                subMenuStyle={{ marginTop: '-60px', marginLeft: '285px' }}
                options={[
                  {
                    label: 'Excluir',
                    onClick: () => handleOpenSelectAccountListExtractModal(),
                  },
                  {
                    label: 'Importar',
                    onClick: () => console.log(''),
                    subItens: [
                      {
                        label: 'OFX',
                        onClick: () => handleOpenSystemicAccountModal('ofx'),
                        type: 'single',
                      },
                      {
                        label: 'Excel',
                        onClick: () => handleOpenSystemicAccountModal('csv'),
                        type: 'single',
                      },
                    ],
                  },
                ]}
              />

              <Button
                variant="outlined"
                startIcon={<FileDownloadOutlined />}
                sx={{ minWidth: '123px', height: '40px' }}
                onClick={() =>
                  ShowModal({
                    content: <ModalReportsExport handle={handleExport} />,
                    title: 'Exportar',
                  })
                }
              >
                <Typography
                  fontWeight="500"
                  fontSize="14px"
                  lineHeight="16.41px"
                >
                  Exportar
                </Typography>
              </Button>
            </Box>
          </Box>

          <Typography
            fontSize="24px"
            fontWeight={700}
            lineHeight="32.69px"
            color="#0B1116"
          >
            Selecione uma conta ou mais para visualizar os detalhes
          </Typography>
        </Stack>

        {isLoading && <CircularProgress />}

        <Stack alignItems="flex-start" mt="-10px" width="calc(100% - 24px)">
          <Stack
            direction="row"
            alignItems="flex-start"
            gap="24px"
            padding="12px"
            width="100%"
            sx={{ overflow: 'auto' }}
          >
            {companyAccounts?.map((account, index) => (
              <BankAccountCard
                key={account.id}
                data={account}
                index={index}
                editable
                value={!!accountsSelected.find((f) => f === account.id)}
                editableClick={() =>
                  navigate(`/company/${companyId}/account/edit/${account.id}`)
                }
                selectable
                onSelect={() => handleSelectAccount(account.id)}
                containerStyle={{
                  width: '268px',
                  height: '80px',
                  justifyContent: 'flex-start',
                }}
                brandStyle={{
                  width: '48px',
                  height: '48px',
                  marginLeft: '10px',
                }}
                amountStyle={{
                  fontSize: '16px',
                }}
                agencyStyle={{
                  marginTop: '3px',
                }}
                accountStyle={{
                  marginTop: '3px',
                }}
              />
            ))}
          </Stack>
        </Stack>

        {!!accountsSelected?.length && !isLoading && (
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Stack id="cashflowContent">
                <CashFlow
                  cashflowConsolidate={cashflowConsolidate}
                  variationData={variationData}
                  minVariation={minVariation}
                  maxVariation={maxVariation}
                  categoriesVariations={categoriesVariations}
                />
              </Stack>
            </Grid>

            <Grid item md={6}>
              <Stack id="extractContentToPrint">
                <Extract data={extract} balance={balance} />
              </Stack>
            </Grid>
          </Grid>
        )}
      </Stack>
    </Stack>
  )
}
